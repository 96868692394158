<template>
  <div>
    <TheSonyBar />
    <TheNavBar />

    <slot />
    <ModalsContainer />
  </div>
</template>

<script setup lang="ts">
import { ModalsContainer, useVfm } from 'vue-final-modal'

const router = useRouter()
const vfm = useVfm()

router.afterEach((to, from) => {
  if (import.meta.server) return

  if (to.name !== from.name || to.name !== 'index') {
    vfm.closeAll()
  }
})
</script>

<style>
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
}
</style>
